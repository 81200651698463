@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&amp;subset=cyrillic,greek-ext);
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Open Sans' !important;
  font-size: 14px;
}

